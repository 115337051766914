import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { WeatherStyle } from "../style/weatherStyle"
import High from "../images/weather/high.gif"
import Low from "../images/weather/low.gif"
import Airport from "../images/weather/airport.gif"
import Pierhead from "../images/weather/pierhead.gif"
import Buoy from "../images/weather/buoy.gif"
import Proudman from "../images/weather/proudman.gif"
import Open from "../images/weather/open.gif"
import Closed from "../images/weather/closed.gif"
import Plus from "../images/plus.gif"
import Reduce from "../images/reduce.gif"
import ChartDemo from "../images/chartDemo.jpg"
import { Link } from "gatsby"


const images = require.context('../images/weather', true);

const Weather = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [heightType, setHeightType] = useState(true);

  const [TodayForecast, setTodayForecast] = useState([]);
  const [ShippingForecast, setShippingForecast] = useState([]);
  const [CurrentTide, setCurrentTide] = useState([]);
  const [FiveDayForecastDay1, setFiveDayForecastDay1] = useState([]);
  const [FiveDayForecastDay2, setFiveDayForecastDay2] = useState([]);
  const [FiveDayForecastDay3, setFiveDayForecastDay3] = useState([]);
  const [FiveDayForecastDay4, setFiveDayForecastDay4] = useState([]);
  const [FiveDayForecastDay5, setFiveDayForecastDay5] = useState([]);

  const [windIcon1, setwindIcon1] = useState(images("./blank.gif"));
  const [windIcon2, setwindIcon2] = useState(images("./blank.gif"));
  const [windIcon3, setwindIcon3] = useState(images("./blank.gif"));
  const [windIcon4, setwindIcon4] = useState(images("./blank.gif"));
  const [windIcon5, setwindIcon5] = useState(images("./blank.gif"));

  const [weatherIcon1, setWeatherIcon1] = useState(images("./blank.gif"));
  const [weatherIcon2, setWeatherIcon2] = useState(images("./blank.gif"));
  const [weatherIcon3, setWeatherIcon3] = useState(images("./blank.gif"));
  const [weatherIcon4, setWeatherIcon4] = useState(images("./blank.gif"));
  const [weatherIcon5, setWeatherIcon5] = useState(images("./blank.gif"));
  const [weatherIcon6, setWeatherIcon6] = useState(images("./blank.gif"));

  const [showHelp, setShowHelp] =  useState(false);

  const [Gate, setGate] = useState("St Helier Marina Gate (5.3M)");
  const [GateStatus, setGateStatus] = useState("");
  const [GateTime, setGateTime] = useState("");

  const [Causeway, setCauseway] = useState("St Aubins Causeway (5.0M)");
  const [CausewayStatus, setCausewayStatus] = useState("");
  const [CausewayTime, setCausewayTime] = useState("");

  const [locationName, setLocationName] =  useState("");
  const [locationValue, setLocationValue] =  useState("0.0");
  const [locationError, setLocationError] =  useState();
  const [locationResult, setLocationResult] =  useState([]);

  const toggleHeightType = () => {
    setHeightType(!heightType)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        const result = await axios(process.env.GATSBY_WEATHER_API)
        setTodayForecast(result.data.TodayForecast)
        setShippingForecast(result.data.ShippingForecast)
        setCurrentTide(result.data.CurrentTide)
        setFiveDayForecastDay1(result.data.FiveDayForecast.forecastDay[0])
        setFiveDayForecastDay2(result.data.FiveDayForecast.forecastDay[1])
        setFiveDayForecastDay3(result.data.FiveDayForecast.forecastDay[2])
        setFiveDayForecastDay4(result.data.FiveDayForecast.forecastDay[3])
        setFiveDayForecastDay5(result.data.FiveDayForecast.forecastDay[4])

        setwindIcon1(images('./wind_'+result.data.FiveDayForecast.forecastDay[0].windDirection.toLowerCase()+'.gif'));
        setwindIcon2(images('./wind_'+result.data.FiveDayForecast.forecastDay[1].windDirection.toLowerCase()+'.gif'));
        setwindIcon3(images('./wind_'+result.data.FiveDayForecast.forecastDay[2].windDirection.toLowerCase()+'.gif'));
        setwindIcon4(images('./wind_'+result.data.FiveDayForecast.forecastDay[3].windDirection.toLowerCase()+'.gif'));
        setwindIcon5(images('./wind_'+result.data.FiveDayForecast.forecastDay[4].windDirection.toLowerCase()+'.gif'));

        setWeatherIcon1(images('./'+result.data.TodayForecast.TodayIcon));
        setWeatherIcon2(images('./'+result.data.FiveDayForecast.forecastDay[0].dayIcon));
        setWeatherIcon3(images('./'+result.data.FiveDayForecast.forecastDay[1].dayIcon));
        setWeatherIcon4(images('./'+result.data.FiveDayForecast.forecastDay[2].dayIcon));
        setWeatherIcon5(images('./'+result.data.FiveDayForecast.forecastDay[3].dayIcon));
        setWeatherIcon6(images('./'+result.data.FiveDayForecast.forecastDay[4].dayIcon));

        setGateStatus(result.data.CurrentTide.marinaStatus);
        setGateTime(result.data.CurrentTide.marinaTime);
        setCausewayStatus(result.data.CurrentTide.StAubinsStatus);
        setCausewayTime(result.data.CurrentTide.StAubinsTime);

      } catch (error) {
        setIsError(true)
        console.log(error);
      }
        setIsLoading(false)
    }
      fetchData();
  },[])

    
  const GateAccess = (e) => {
    console.log(e)
    if (e.target.value==="StHelier"){
      console.log(heightType);
      if (heightType) setGate("St Helier Marina Gate (5.3M)"); else setGate("St Helier Marina Gate (17.4Ft)"); 
      setGateStatus(CurrentTide.marinaStatus);
      setGateTime(CurrentTide.marinaTime);
    }
    else
    {
      if (heightType) setGate("Elizabeth Marina Gate (5.5M)"); else setGate("Elizabeth Marina Gate (18.0Ft)"); 
      setGateStatus(CurrentTide.ElizabethMarinaStatus);
      setGateTime(CurrentTide.ElizabethMarinaTime);
    }
  }

  const CausewayAccess = (event) => {
    if (event.target.value==="Aubins"){
      if (heightType) setCauseway("St Aubins Causeway (5.0M)"); else setCauseway("St Aubins Causeway (16.4Ft)");
      setCausewayStatus(CurrentTide.StAubinsStatus);
      setCausewayTime(CurrentTide.StAubinsTime);
    }
    else if(event.target.value==="Corbiere")
    {
      if (heightType) setCauseway("Corbiere Lighthouse Causeway (5.5M)"); else setCauseway("Corbiere Lighthouse Causeway (18.1Ft)");
      setCausewayStatus(CurrentTide.CorbiereStatus);
      setCausewayTime(CurrentTide.CorbiereTime);
    }
    else if(event.target.value==="ElizabethCastle")
    {
      if (heightType) setCauseway("Elizabeth Castle Causeway (4.8M)"); else setCauseway("Elizabeth Castle Causeway (15.7Ft)");
      setCausewayStatus(CurrentTide.ElizabethCastleStatus);
      setCausewayTime(CurrentTide.ElizabethCastleTime);
    }
    else
    {
      if (heightType) setCauseway("Havre des Pas Swimming Pool (7.6M)"); else setCauseway("Havre des Pas Swimming Pool (25.0Ft)");
      setCausewayStatus(CurrentTide.HavreStatus);
      setCausewayTime(CurrentTide.HavreTime);
    }
  }

  const handleSubmit = async(event) => {
    event.preventDefault();

    if (locationResult.length < 10) {
      if (locationValue > 0.0 && locationValue <= 10.0) {
        try {
          const result = await axios(process.env.GATSBY_WEATHERCALC_API+'&height=' + locationValue)   
          setLocationResult(locationResult => [...locationResult, {'id': locationResult.length+1, 'Name':locationName, 'Height':locationValue, "Time": result.data.tideTime, "Status":  result.data.tideStatus, "Description":  result.data.tideDescription}])
          setLocationName("");
          setLocationValue("0.0");
        } catch (error) {
          console.log(error);
        }
    
      }
      else
      setLocationError("Boat height should be between 0.0 and 10.0");
    }
  }

  
  const handleRemove = (id) => {
    var array = locationResult;
    for(var i = array.length - 1; i >= 0; i--) {
      if(array[i].id === id) {
          array.splice(i, 1);
      }
    }
    
    setLocationName("");
    setLocationValue("0.0");
    setLocationResult(array);
  }

  if (isError) return <div><b>Forecast data is currently unavailable</b></div>;
  return (
    <div css={WeatherStyle}>
      <div id="WeatherContent">
      <div id="nav">
        <ul>
          <li>
            <a href="#shipping" name="top">
              Shipping Forecast
            </a>
          </li>
          <li>
            <a href="#5day">5 Day Forecast</a>
          </li>
          <li>
            <a href="#tidedata">Tides</a>
          </li>
        </ul>
      </div>
      <div className="headleft"><h2 className="dashboardLink" id="today">Weather Dashboard</h2></div>
      <div className="heightinFt"><button className="toggleheight" onClick={toggleHeightType} href="/weather?TideDisplay=Ft">{heightType ? "Height in feet" : "Height in Metres"}</button></div>
      <div className="line"></div>
      <div className="dashboard airport weather">
        <h1>Summary</h1>
        <div className="weatherContainer">
          <img src={weatherIcon1} className="icon" alt="weather" />
          <div className="left">
            <span className="largeText">
              <strong>{TodayForecast.MinTemp}°c&nbsp;&nbsp;</strong>
            </span>
            <br />
            <small>min</small>
          </div>
          <div className="left">
            <span className="largeText">
              <strong>{TodayForecast.MaxTemp}°c</strong>
            </span>
            <br />
            <small>max</small>
          </div>
         </div>
      </div>
      <div className="dashboard proudman tides">
        <h1>Tide (live)</h1>
        <div className="weatherContainer">
        <div className="left">
          <span className="largeText">
          {heightType ? <strong>{CurrentTide.CurrentTideHeight}</strong> : <strong>{CurrentTide.CurrentTideHeightFeet}</strong>}
          </span>
          <br />
          <small>(estimated)</small>
        </div>
        </div>
      </div>
      <div className="dashboard proudman nexttides">
        <h1>Next Tides</h1>  
          <div className="weatherContainer">
          {CurrentTide.firstTideStatus === "High" ? <img src={High} className="icon" alt="High" /> : <img src={Low} className="icon" alt="Low" />}
          <div className="left rightPad">
          <span className="largeText">
          <strong>{CurrentTide.firstTideTime}</strong>
          </span>
          <br />
          {heightType ? <small>{CurrentTide.firstTideHight}</small>: <small>{CurrentTide.firstTideHeightFeet}</small> }
        </div>
        {CurrentTide.secondTideStatus === "High" ? <img src={High} className="icon" alt="High" /> : <img src={Low} className="icon" alt="Low" />}
        <div className="left rightPad">
          <span className="largeText">
            <strong>{CurrentTide.secondTideTime}</strong>
          </span>
          <br />
          {heightType ? <small>{CurrentTide.secondTideHight}</small> : <small>{CurrentTide.secondTideHeightFeet}</small> }
        </div>
      </div>
      </div>

      <div className="clear"></div>
      <img src={Airport} className="icon source" alt="airport" />
      <p>Issued by the Jersey Met Office.</p>
      <div className="clear"></div>
      {/* <img src={Proudman} className="icon source" alt="proudman" />
      <p>
        Tidal forecast for St Helier by Proudman, one hour has been added for
        BST when applicable.
      </p>
      <div className="clear"></div> */}
      <div>
        <ul className="top">
          <li>
            <a href="#top">Page top</a>
          </li>
        </ul>
      </div>
      <div className="clear"></div>
      <h2 id="shipping">Shipping Forecast</h2>
      <div className="line"></div>
      <div className="scrollable">
        <div>
          <table className="forecast cells">
            <tbody>
              <tr className="heading">
                <th colSpan="4">
                <h3>{ShippingForecast.forecastTimeToday}</h3>
                </th>
              </tr>
              <tr className="quarter">
                <th>Weather</th>
                <th>Wind</th>
                <th>Visibility</th>
                <th>Sea State</th>
              </tr>
              <tr className="quarter">
                <td>
                 {ShippingForecast.weatherToday}
                </td>
                <td>
                  {ShippingForecast.winddescToday}
                </td>
                <td>{ShippingForecast.visibilityToday}</td>
                <td>{ShippingForecast.seastateToday}</td>
              </tr>
              <tr className="heading">
                <th colSpan="4">
                <h3>{ShippingForecast.forecastTimeTomorrow}</h3>
                </th>
              </tr>
              <tr className="quarter">
                <th>Weather</th>
                <th>Wind</th>
                <th>Visibility</th>
                <th>Sea State</th>
              </tr>
              <tr className="quarter">
                <td>
                 {ShippingForecast.weatherTomorrow}
                </td>
                <td>
                  {ShippingForecast.winddescTomorrow}
                </td>
                <td>{ShippingForecast.visibilityTomorrow}</td>
                <td>{ShippingForecast.seastateTomorrow}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="clear"></div>
      <img src={Airport} className="icon source" alt="airport" />
      <p>
        Issued by the Jersey Met Office. The Channel Islands shipping forecast
        covers the area bounded by latitude 50ºN, the French coasts between Cap
        de la Hague and Ile de Brehat, and longitude 3ºW.
      </p>
      <div>
        <ul className="top">
          <li>
            <a href="#top">Page top</a>
          </li>
        </ul>
      </div>
      <div className="clear"></div>
      <h2 id="5day">5 Day Forecast</h2>
      <div className="line"></div>
      <div className="scrollable">
        <div>
          <table className="forecast fiveday">
            <tbody>
              <tr>
                <th>Day</th>
                <th>Weather/Max</th>
                <th>Wind Dir./Speed</th>
                <th>Wind Force</th>
                <th>UV Level</th>
                <th></th>
              </tr>
              <tr className="middle">
                <td>{FiveDayForecastDay1.dayName}</td>
                <td className="nobr">
                  <img src={weatherIcon2} alt="" />
                  <strong>
                    <span className="largeText">
                    {FiveDayForecastDay1.maxTemp}
                    </span>
                  </strong>
                </td>
                <td className="nobr">
                  <img src={windIcon1} alt="" />
                  <strong>
                    <span className="largeText">{FiveDayForecastDay1.windDirection}</span> ({FiveDayForecastDay1.windSpeedMPH}mph)
                  </strong>
                </td>
                <td>
                  <span className="largeText">
                    <strong>{FiveDayForecastDay1.windSpeed}</strong>
                  </span>
                </td>
                <td className="nobr">
                  <strong>
                    <span className="largeText">{FiveDayForecastDay1.uvIndex}</span>
                  </strong>
                </td>
              </tr>
              <tr className="middle">
                <td>{FiveDayForecastDay2.dayName}</td>
                <td className="nobr">
                  <img src={weatherIcon3} alt="" />
                  <strong>
                    <span className="largeText">
                    {FiveDayForecastDay2.maxTemp}
                    </span>
                  </strong>
                </td>
                <td className="nobr">
                  <img src={windIcon2} alt="" />
                  <strong>
                    <span className="largeText">{FiveDayForecastDay2.windDirection}</span> ({FiveDayForecastDay2.windSpeedMPH}mph)
                  </strong>
                </td>
                <td>
                  <span className="largeText">
                    <strong>{FiveDayForecastDay2.windSpeed}</strong>
                  </span>
                </td>
                <td className="nobr">
                  <strong>
                    <span className="largeText">{FiveDayForecastDay2.uvIndex}</span>
                  </strong>
                </td>
              </tr>
              <tr className="middle">
                <td>{FiveDayForecastDay3.dayName}</td>
                <td className="nobr">
                  <img src={weatherIcon4} alt="" />
                  <strong>
                    <span className="largeText">
                    {FiveDayForecastDay3.maxTemp}
                    </span>
                  </strong>
                </td>
                <td className="nobr">
                  <img src={windIcon3} alt="" />
                  <strong>
                    <span className="largeText">{FiveDayForecastDay3.windDirection}</span> ({FiveDayForecastDay3.windSpeedMPH}mph)
                  </strong>
                </td>
                <td>
                  <span className="largeText">
                    <strong>{FiveDayForecastDay3.windSpeed}</strong>
                  </span>
                </td>
                <td className="nobr">
                  <strong>
                    <span className="largeText">{FiveDayForecastDay3.uvIndex}</span>
                  </strong>
                </td>
              </tr>
              <tr className="middle">
                <td>{FiveDayForecastDay4.dayName}</td>
                <td className="nobr">
                  <img src={weatherIcon5} alt="" />
                  <strong>
                    <span className="largeText">
                      {FiveDayForecastDay4.maxTemp}
                    </span>
                  </strong>
                </td>
                <td className="nobr">
                  <img src={windIcon4} alt="" />
                  <strong>
                  <span className="largeText">{FiveDayForecastDay4.windDirection}</span> ({FiveDayForecastDay4.windSpeedMPH}mph)
                  </strong>
                </td>
                <td>
                  <span className="largeText">
                  <strong>{FiveDayForecastDay4.windSpeed}</strong>
                  </span>
                </td>
                <td className="nobr">
                  <strong>
                    <span className="largeText">{FiveDayForecastDay4.uvIndex}</span>
                  </strong>
                </td>
              </tr>
              <tr className="middle">
                <td>{FiveDayForecastDay5.dayName}</td>
                <td className="nobr">
                  <img src={weatherIcon6} alt="" />
                  <strong>
                    <span className="largeText">
                      {FiveDayForecastDay5.maxTemp}
                    </span>
                  </strong>
                </td>
                <td className="nobr">
                  <img src={windIcon5} alt="" />
                  <strong>
                    <span className="largeText">{FiveDayForecastDay5.windDirection}</span> ({FiveDayForecastDay5.windSpeedMPH}mph)
                  </strong>
                </td>
                <td>
                  <span className="largeText">
                    <strong>{FiveDayForecastDay5.windSpeed}</strong>
                  </span>
                </td>
                <td className="nobr">
                  <strong>
                    <span className="largeText">{FiveDayForecastDay5.uvIndex}</span>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="clear"></div>
      <div className="headleft"><h2 id="tidedata">Tides</h2></div>
      <div className="heightinFt"><button onClick = {() => setShowHelp(!showHelp)} className="toggleheight">Click for help</button></div>
        {showHelp && <div id="ChartDemo"><img src={ChartDemo} alt="" /></div> }
      <div className="line"></div>
      <img className="tidechart" src={heightType ? process.env.GATSBY_TIDE_CHART_URL : process.env.GATSBY_TIDE_CHART_FEET_URL } alt="" />
      <div className="clear"></div>
      {/* <img src={Proudman} className="icon source" alt="proudman" />
      <p>Tidal forecast for St Helier by Proudman, one hour has been added for BST when applicable.</p> */}
      <div className="clear"></div>

      {/* <h3>Marina Gate Access Calculator</h3>
      <div className="marinagate">
        <div className="tidalcalc tcleft">   
          <h2>Location</h2>
          <select onChange={GateAccess} id="TideGate_ddlHarbour">
            <option value="StHelier">St Helier Marina Gate</option>
            <option value="Elizabeth">Elizabeth Marina Gate</option>
        	</select>
          <p>Click on drop down menu to select location.</p>
        </div>
        <div className="tidalcalc tcright">  
          <h2>Tidal Access</h2>
          <strong>{GateStatus === "open" ?<img className="centeredbutton" src={Open} alt="Open" />: <img className="centeredbutton" src={Closed} alt="Closed" />}
              <span >{heightType ? Gate.replace("(17.4Ft)", "(5.3M)").replace("(18.0Ft)", "(5.5M)") : Gate.replace("(5.3M)", "(17.4Ft)").replace("(5.5M)", "(18.0Ft)")}</span><br />
              <span className="tidegateleft">{GateStatus === "open" ? "open": "closed"} for boats</span> 
          </strong>
          <div className="tidegateleft">
              <p>({GateStatus === "open" ? "closing": "opening"} {GateTime})</p>
          </div>
        </div>
      </div> */}
<h2 style={{marginTop:"20px", marginBottom: "30px", textAlign:"center"}}>
<Link className='payOnlineLink'
            to="/jerseymarinas/opening-times/"
          >
            Marina opening times
          </Link></h2>
      <h3>Causeway Access Calculator (for pedestrians)</h3>
      <div className="marinagate">         
        <div className="tidalcalc tcleft">
          <h2>Location</h2>
          <select onChange={CausewayAccess} id="TideGate_ddlCauseway">
            <option value="Aubins">St Aubins Causeway</option>
            <option value="Corbiere">Corbiere Lighthouse Causeway</option>
            <option value="ElizabethCastle">Elizabeth Castle Causeway</option>
            <option value="Havre">Havre des Pas Swimming Pool</option>
          </select>
          <p>Click on drop down menu to select location.</p>
        </div>
        <div className="tidalcalc tcright">
          <h2>Tidal Access</h2> 
          <strong>{CausewayStatus === "open" ?<img className="centeredbutton" src={Open} alt="Open" />: <img className="centeredbutton" src={Closed} alt="Closed" />}
            <span>{heightType ? Causeway.replace("(16.4Ft)", "(5.0M)").replace("(18.1Ft)", "(5.5M)").replace("(15.7Ft)", "(4.8M)").replace("(25.0Ft)", "(7.6M)") : Causeway.replace("(5.0M)", "(16.4Ft)").replace("(5.5M)", "(18.1Ft)").replace("(4.8M)", "(15.7Ft)").replace("(7.6M)", "(25.0Ft)")}</span><br />
            <span className="tidegateleft">{CausewayStatus === "open" ? "open": "closed"} for pedestrians</span>
          </strong>
          <div className="tidegateleft">
            <p>({CausewayStatus === "open" ? "closing": "opening"} {CausewayTime})</p>
          </div>
        </div>
      </div>
      <h3>My Locations / Boats</h3>
      <p>To find out when your boat starts to float or dry out, enter your boat name and height above chart datum and click on the plus sign.</p>
      <p>Similarly, this can be done for other specific locations by entering the location name and height above chart datum and clicking on the plus sign. You may have up to ten locations. The first location will appear in the tides area on the right hand side of each website page.</p>
      <div className="marinagate"> 
        <div className="tidalcalc tcleft">
          Please enter your boat name / rock name / location and their respective Height (M) above chart datum *
          <div id="LocationError">{locationError}</div>
        </div>
        <div className="tidalcalc tcright">
          <form onSubmit={handleSubmit} >
            <input type="text" value={locationName}  onChange={e => setLocationName(e.target.value)} placeholder="enter boat name" id="TideGate_txtBoatName" className="width160" autoComplete="off" />
            <input type="text" value={locationValue} onChange={e => setLocationValue(e.target.value)} id="TideGate_txtBoatHeight" className="width40 inputspacing" />
            <input  type="image" id="TideGate_btnAddBoat" src={Plus} alt="submit" className="button" text="+" />
          </form>
          <table id="tideResult" className="tidegate">
            <tbody>
            {locationResult.map(result =>            
              <tr key={result.id}>
                <td>
                {result.Status === "open" ? <img src={Open} alt="Open" /> : <img src={Closed} alt="Closed" /> }{result.Name} - {result.Height}M {result.Description} 
                  <button style={{borderStyle:"none"}} className="button" onClick={e => handleRemove(result.id)} ><img src={Reduce} alt="Remove item" /></button>
                </td>
              </tr>    
              )}  
            </tbody>
          </table>

         </div>
      </div>
      <br />
      <p>Here are two examples.</p>
      <p><strong>1. Gorey Harbour</strong><br />
        The pier at Gorey dries at 4.0m ABOVE chart datum. Therefore at
        a tide height of 4.0m the harbour will just be awash. A vessel of 1.0m draft wishing
        to navigate safely with no less than 0.5m under keel clearance (UKC) should add
        1.5m to a drying height to calculate the time to safely enter the harbour. Therefore
        enter 4 + 1.5 = 5.5 to calculate the earliest or latest safe time. 
      </p>
      <p><strong>2. Castle Breakwater End passage approaching St Helier</strong> <br />
        The nearest danger to this track is a rock that
        is awash just west of the breakwater end. Awash means the rock is at chart datum,
        or zero height. Therefore the same boat drawing 1.0m and requiring 0.5m UKC should
        not enter this passage until there is at least 1.5m of tide.
      </p>
      <p>To ensure safe under keel clearance, mariners should also take into account any variable conditions that could affect the calculation (e.g. swell, wind, waves, atmospheric pressure etc.)</p>
      {/* <img src={Proudman} className="icon source" alt="airport"></img>
      <p>Tidal forecast for St Helier by Proudman, one hour has been added for BST when applicable.</p> */}
      <p>* This functionality requires your browser to accept cookies.</p>
      <div>
        <ul className="top">
          <li><a href="#top">Page top</a></li>
        </ul>
      </div>
      <div className="clear"></div>
    </div>
    </div>
  )
}

export default Weather
